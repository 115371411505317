<template>
  <TableV
    :actionBarButtons="actionBarButtons"
    :cols="cols"
    :disableTableSaveButton="disableTableSaveButton"
    :hasExportCSV="false"
    :lastRowData="lastRowData"
    :opts="opts"
    :rows="rows"
    @contextmenu="contextHandler"
    @save="doUpdate"
  >
    <div class="d-flex">
      <v-menu
        v-model="periodMenu"
        :close-on-click="false"
        :close-on-content-click="false"
        transition="scroll-y-transition"
        offset-y
        right
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="width: 370px"
            prefix="Período:"
            :value="period | toPeriod"
            dense
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            @click:prepend.stop="on.click"
            v-on="on"
            hide-details
            class="mb-n1 mt-0"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="period"
          range
          no-title
          @mouseenter:date="highlightWeek"
          @mouseleave:date="highlightWeekOver = []"
          :events="highlightWeekOver"
          :event-color="tableColor"
          @click:date="dateClick"
        ></v-date-picker>
      </v-menu>
      <v-autocomplete
        v-if="isUserColaborador"
        v-model="colaboradorId"
        :items="opts.colaboradores"
        class="mb-n1 mt-0 ml-3"
        style="width: 370px"
        item-value="id"
        item-text="nome"
        prefix="Colaborador"
        prepend-icon="mdi-account"
        placeholder="Todos"
        dense
        clearable
        hide-details
        @change="debouncedLoad"
      ></v-autocomplete>
    </div>

    <FormModal
      :cols="cols"
      :errorMessage="createModalMessage"
      :opened.sync="openedCreateModal"
      :opts="opts"
      title="Alocar semana de trabalho"
      :value.sync="selectedItem"
      @save="createModalSave"
    />

    <GModal ref="confirmDeleteDay" title="Confirmar exclusão do dia">
      <p style="max-width: 500px">
        Deseja excluir o registro do dia <b>{{ selectedItem.dia }}</b> para o
        projeto <b>{{ selectedItem.projeto || "Título não encontrado" }}</b>
        <template v-if="selectedItem.atividade">
          <span>, atividade <b>{{ selectedItem.atividade }}</b></span>
        </template>
        <template v-if="selectedItem.funcionarioNome">
          <span>, colaborador <b>{{ selectedItem.funcionarioNome }}</b></span>
        </template>
        ?
      </p>
      <p>Essa ação não pode ser desfeita.</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn class="px-3" color="primary" dark depressed @click="close(true)">
          Sim
        </v-btn>
        <v-spacer />
      </template>
    </GModal>

    <GModal ref="confirmDeleteWeek" title="Confirmar exclusão">
      <p style="max-width: 500px">
        Deseja excluir todos os registros da semana para o projeto
        <b>{{ selectedItem.projeto || "Título não encontrado" }}</b>
        <template v-if="selectedItem.atividade">
          <span>, atividade <b>{{ selectedItem.atividade }}</b></span>
        </template>
        <template v-if="selectedItem.funcionarioNome">
          <span>, colaborador <b>{{ selectedItem.funcionarioNome }}</b></span>
        </template>
        ?
      </p>
      <p>Essa ação não pode ser desfeita.</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn class="px-3" color="primary" dark depressed @click="close(true)">
          Sim
        </v-btn>
        <v-spacer />
      </template>
    </GModal>
  </TableV>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { UserTypeEnum } from "@/core/enums/user-types";
import compareFloatNum from "@/helpers/compareFloatNum";
import { debounce } from "lodash";

export default {
  components: {
    FormModal: () => import("@/components/form-modal.vue"),
    GModal: () => import("@/components/g-modal.vue"),
    TableV: () => import("@/components/table-v.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "user"]),
    actionBarButtons() {
      return [{
        text: "Adicionar",
        icon: "mdi-plus-box-outline",
        action: async () => {
          const [dataIni] = this.period;
          const ano = moment(dataIni).format("YYYY");
          await this.getProjetosByYear(ano);
          this.selectedItem = {};
          this.openedCreateModal = true;
          this.createModalMessage = null;
        },
      }];
    },
    cols: function () {
      const created = this.rows.some(({ atividadeId, funcionarioId, projetoId }) => {
        const funcionarios = Array.isArray(this.selectedItem.funcionarioId) ? this.selectedItem.funcionarioId : [this.selectedItem.funcionarioId];
        return this.selectedItem.projetoId === projetoId && this.selectedItem.atividadeId === atividadeId && (!this.isUserColaborador || funcionarios.includes(funcionarioId));
      });
      const days = this.week.map((day) => ({
        key: day,
        name: this.$options.filters.toWeekDay(day),
        type: this.$fieldTypes.TIME,
        editOnTable: true,
        editable: this.dateInsideProjectPeriod(day),
        hideInform: !this.selectedItem.atividadeId || created || this.dateInsideProjectPeriod(day, true),
        align: 0,
        width: 120,
        colSize: 3,
        commentForField: `comment${day}`,
        valueChanged: this.updateTotalCol,
      }));

      return [
        {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: this.opcoesProjetosParsed, key: "id", name: "titulo" },
          hideInTable: true,
          rules: [{ rule: "required" }],
        },
        {
          key: "projeto",
          name: "Projeto",
          hideInform: true,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "atividadeId",
          name: "Atividade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "atividades", key: "id", name: "atividade" },
          rules: [{ rule: "required" }],
          hideInTable: true,
        },
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "funcionarioId",
          name: "Colaborador",
          hideInform: !!this.funcionarioId,
          hideInTable: true,
          defaultValue: !this.funcionarioId ? [] : [this.funcionarioId],
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "colaboradores", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "colaborador",
          name: "Colaborador",
          hideInform: true,
          hideInTable: !!this.funcionarioId,
          type: this.$fieldTypes.TEXT,
        },
        ...days,
        {
          key: "alreadyCreated",
          name: this.isUserColaborador ? "Um ou mais colaboradores já possuem horas alocadas para este projeto e atividade nesta semana. Edite diretamente na tabela." : "Esse projeto e atividade já possuem horas alocadas para esta semana. Edite diretamente na tabela.",
          type: this.$fieldTypes.SUBTITLE,
          hideInTable: false,
          hideInform: !created,
          colSize: 12,
        },
        {
          key: "total",
          name: "Total",
          type: this.$fieldTypes.TIME,
          editable: false,
          hideInform: true,
        },
      ];
    },
    funcionarioId: function () {
      return this.user.funcionarioId;
    },
    lastRowData: function () {
      const totals = this.week.reduce((prev, date) => ({ ...prev, [date]: 0 }), {
        colaborador: "Total",
        total: 0,
      });
      return this.rows.reduce((acc, date) => {
        return [...this.week, 'total'].reduce(
          (prev, day) => ({ ...prev, [day]: prev[day] + (date[day] ?? 0) }),
          acc
        );
      }, totals);
    },
    opcoesProjetosParsed: function () {
      const periodoInicial = this.week[0];
      const periodoFinal = this.week[6];
      return this.opts.projetos
        .map(({ data_fim, data_inicio, id, titulo, ...rest }) => {
          const disabled =
            data_inicio &&
            data_fim &&
            !(
              data_inicio <= periodoInicial &&
              periodoInicial <= data_fim &&
              data_inicio <= periodoFinal &&
              periodoFinal <= data_fim
            );
          const safeTitle =
            (titulo || "Titulo não encontrado") +
            (disabled ? " (Projeto já finalizado)" : "");
          return {
            data_fim,
            data_inicio,
            disabled,
            id,
            titulo: safeTitle,
            ...rest,
          };
        })
        .sort((a, b) => a.titulo.localeCompare(b.titulo));
    },
    resourceAtividades: function () {
      return this.apiResource(`/v1/timesheet/${this.clientId}/atividades`);
    },
    resourceColaboradores: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/selecao`);
    },
    isUserColaborador: function () {
      return this.user.tipo_usuario != UserTypeEnum.COLABORADOR;
    },
    week() {
      return this.weekFromDate(this.period[0]);
    },
  },
  created: function () {
    let dataIni = this.$route.query.dataIni;
    let dataFim = this.$route.query.dataFim;

    if (dataIni && dataFim) {
      this.period = [dataIni, dataFim];
    } else {
      this.period = this.getFilters("period") || this.getWeekPeriod(moment());
    }

    this.resourceColaboradores.get().then((response) => {
      this.opts.colaboradores = response.colaboradores
        .sort((a, b) => a.nome.localeCompare(b.nome))
        .map((col) => ({ ...col, nome: `${col.matricula} — ${col.nome}` }));
    });

    this.resourceAtividades.get().then((response) => {
      this.opts.atividades = response;
    });

    this.debouncedLoad = debounce(this.doLoad, 200);
    this.debouncedLoad();
  },
  data: function () {
    return {
      colaboradorId: null,
      createModalMessage: null,
      debouncedLoad: () => {},
      highlightWeekOver: [],
      openedCreateModal: false,
      period: [],
      periodMenu: false,
      rows: [],
      selectedItem: {},
      opts: {
        atividades: [],
        colaboradores: [],
        projetos: [],
        projetosCadastro: [],
      },
    };
  },
  methods: {
    contextHandler(row, event, _expanded, col) {
      const options = [
        {
          name: "Excluir dia",
          limit: 1,
          show: moment(col.value, "YYYY-MM-DD").isValid(),
          cb: (row, col) => this.doDeleteDay(row, col),
        },
        {
          name: "Excluir semana",
          limit: 1,
          show: true,
          cb: (row) => this.doDeleteWeek(row),
        },
      ];

      this.showContext({
        left: event.clientX,
        top: event.clientY,
        val: row,
        col,
        menu: options.filter(({ show }) => show),
      });
    },
    async createModalSave(data, closeFn) {
      try {
        await this.doCreate(data);
        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        this.createModalMessage = this.errorHandler(error);
      }
    },
    dateClick: function (day) {
      this.period = this.getWeekPeriod(moment(day, "YYYY-MM-DD"));
      this.periodMenu = this.period.length != 2;

      if (!this.periodMenu) {
        this.debouncedLoad();
      }
    },
    dateInsideProjectPeriod: function (date, inverse = false) {
      return (linha) => {
        if (!linha || !Array.isArray(this.opts.projetos)) {
          return true;
        }

        const { projetoId } = linha;
        const projeto = this.opts.projetos.find(({ id }) => id === projetoId);

        if (!projeto) {
          return true;
        }

        const { data_inicio, data_fim } = projeto;
        const res =
          !data_inicio ||
          !data_fim ||
          (data_inicio <= date && date <= data_fim);

        return inverse ? !res : res;
      };
    },
    disableTableSaveButton: function (row) {
      const exist = row.originalData;
      const datas = Object.values(exist.datas);
      const hasChanges = !this.week.some((day) => {
        const timesheet = datas.find(({ data }) => data === day);

        if (!timesheet) {
          return row[day] > 0;
        }

        const tempoPrev = timesheet?.tempo || 0;
        const tempoPos = row[day] || 0;
        const comentarioPrev = timesheet?.comentario ?? "";
        const comentarioPos = row[`comment${day}`] ?? "";

        return (
          !compareFloatNum(tempoPos, tempoPrev) ||
          comentarioPos.localeCompare(comentarioPrev)
        );
      });

      return hasChanges;
    },
    async doCreate(obj) {
      const body = this.formatTimeSheet(obj);
      const { save } = this.apiResource(`/v1/timesheet/${this.clientId}`);
      const response = await save(body);
      const nextRows = this.parseTimeSheet(response);
      this.rows.unshift(...nextRows);
    },
    async doDeleteDay(row, col) {
      const PROCESS_NAME = `alocar-horas-delete-day`;
      const { atividade, datas, funcionarioId, funcionarioNome, projeto } = row.originalData;
      const data = datas.find(({ data }) => col.value === data);
      const id = data?.id;

      if (!id || isNaN(id)) {
        throw new Error(`Não foi possível encontrar o timesheet com id ${id}.`);
      }

      if (!funcionarioId || isNaN(funcionarioId)) {
        throw new Error(`funcionarioId ${funcionarioId} não é um valor válido!`);
      }

      try {
        this.selectedItem = {
          atividade,
          dia: col.text,
          funcionarioNome,
          projeto,
        };
        const confirm = await this.$refs.confirmDeleteDay.asyncOpen();
        this.selectedItem = {};

        if (!confirm) {
          return;
        }

        this.$root.$emit("addLoadingProcess", PROCESS_NAME);
        const resource = this.apiResource(`/v1/timesheet/${this.clientId}/${funcionarioId}`);
        const response = await resource.delete(id);
        
        if (this.formatTimeSheet(row).datas.length > 1) {
          row[col.value] = null;
          data.tempo = null;
        } else {
          const index = this.rows.indexOf(row);

          if (index >= 0) {
            this.rows.splice(index, 1);
          }
        }

        this.updateTotalCol();

        return response;
      } catch (error) {
        return error; // TODO melhorar
      } finally {
        this.$root.$emit("removeLoadingProcess", PROCESS_NAME);
      }
    },
    async doDeleteWeek(row) {
      const PROCESS_NAME = `alocar-horas-delete-week`;

      try {
        this.selectedItem = {
          atividade: row.atividade,
          funcionarioNome: row.funcionarioNome,
          projeto: row.projeto,
        };
        const confirm = await this.$refs.confirmDeleteWeek.asyncOpen();
        this.selectedItem = {};

        if (!confirm) {
          return;
        }

        this.$root.$emit("addLoadingProcess", PROCESS_NAME);
        const { funcionarioId } = row.originalData;
        const { datas } = this.formatTimeSheet(row);
        const response = await this.api().delete(`/v1/timesheet/${this.clientId}/${funcionarioId}`, { data: { datas } });
        const index = this.rows.indexOf(row);

        if (index >= 0) {
          this.rows.splice(index, 1);
        }

        this.updateTotalCol();

        return response;
      } catch (error) {
        return error;
      } finally {
        this.$root.$emit("removeLoadingProcess", PROCESS_NAME);
      }
    },
    async doLoad() {
      const PROCESS_NAME = `alocar-horas-load`;
      try {
        this.$root.$emit("addLoadingProcess", PROCESS_NAME);
        const [dataIni, dataFim] = this.period;
        let query = `dataIni=${dataIni}&dataFim=${dataFim}`;
  
        if (this.colaboradorId) {
          query += `&funcionarioId=${this.colaboradorId}`;
        }
  
        const { get } = this.apiResource(`/v1/timesheet/${this.clientId}/semanal`);
        const response = await get({ query }); 
        const safeTimesheets = Array.isArray(response) ? response : [];
        this.rows = this.parseTimeSheet(safeTimesheets);
      } catch (error) {
        this.rows = [];
      } finally {
        this.$root.$emit("removeLoadingProcess", PROCESS_NAME);
      }

      return this.rows;
    },
    async doUpdate(row) {
      const body = this.formatTimeSheet(row);
      const { save } = this.apiResource(`/v1/timesheet/${this.clientId}/${body.funcionarioId}`);
      const response = await save(body);
      const [nextRow] = this.parseTimeSheet([response]);
      const index = this.rows.indexOf(row);
      this.rows.splice(index, 1, nextRow);
      this.updateTotalCol();
      return response;
    },
    getWeekPeriod: function (date) {
      let anoBase = date.get('year');
      let today = moment(date);
      let start = today.day(0);
      let end = moment(start).day(6);

      if (start.get('year') !== anoBase) {
        start = moment(`${anoBase}-01-01`, "YYYY-MM-DD");
      }
      
      if (end.get('year') !== anoBase) {
        end = moment(`${anoBase}-12-31`, "YYYY-MM-DD");
      }

      return [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")];
    },
    getProjetosByYear: async function (year) {
      try {
        const resource = this.apiResource(
          `/v1/projetos/${this.clientId}/selecao?ano=${year}`
        );
        const response = await resource.get();

        if (response.error) {
          throw response;
        }

        const projetos = Array.isArray(response.projetos)
          ? response.projetos
          : [];
        this.opts.projetos = projetos;
      } catch (error) {
        this.opts.projetos = [];
        throw error;
      }
    },
    highlightWeek: function (date) {
      this.highlightWeekOver = this.weekFromDate(date);
    },
    formatTimeSheet: function (row) {
      const { datas, ...props } = row;
      const nextDatas = this.week.reduce((acc, day) => {
        if (day in props && props[day] > 0) {
          let found =
            Object.values(datas ?? {}).find(({ data }) => data === day) ?? {};
          return [
            ...acc,
            {
              ...found,
              data: day,
              tempo: props[day],
              comentario: props[`comment${day}`] ?? "",
            },
          ];
        }

        return acc;
      }, []);

      this.week.forEach((day) => {
        delete props[day];
        delete props[`comment${day}`];
      });
      delete props.colaborador;
      delete props.originalData;
      delete props.total;

      // tipo 1 indica ser um timesheet diário (sendo 2 um timesheet mensal)
      return { ...props, datas: nextDatas, tipo: 1 };
    },
    parseTimeSheet: function (timesheets) {
      if (!Array.isArray(timesheets)) {
        return [];
      }

      return timesheets.map((weeksheet) => {
        const datas = weeksheet.datas ? Object.values(weeksheet.datas) : [];
        return {
          ...weeksheet,
          colaborador: `${
            weeksheet.funcionarioMatricula ?? "SEM MATRICULA"
          } — ${weeksheet.funcionarioNome}`,
          originalData: weeksheet,
          total: datas.reduce((acc, { tempo }) => acc + tempo, 0),
          ...datas.reduce(
            (prev, { data, tempo, comentario }) => ({
              ...prev,
              [data]: tempo,
              [`comment${data}`]: comentario,
            }),
            {}
          ),
        };
      });
    },
    weekFromDate: function (date) {
      let today = moment(date);
      let [start, end] = this.getWeekPeriod(today);
      let size = moment(end).diff(moment(start), 'days');
      return new Array(size + 1).fill(start).map((_, index) => moment(start).add(index, 'days').format("YYYY-MM-DD"));
    },
    updateTotalCol() {
      if (!Array.isArray(this.rows)) {
        return;
      }

      this.rows = this.rows.map((row) => {
        const total = this.week.reduce((acc, date) => acc + (row[date] ?? 0), 0);
        return {
          ...row,
          total,
        };
      });
    },
  },
  watch: {
    clientId() {
      this.debouncedLoad();
    },
    period: function (value) {
      this.setFilters({ period: value });
    },
  },
};
</script>